import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * A service for Page Visibility
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
@Injectable({
  providedIn: 'root',
})
export class PageVisibilityService {
  private subject = new Subject<boolean>();

  private onVisibilityChange = () => {
    this.subject.next(this.isVisible);
  };

  constructor() {
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  get isVisible() {
    return !document.hidden;
  }

  get isHidden() {
    return document.hidden;
  }

  /** `true` if visible, `false` otherwise (=hidden) */
  get visibilityChange$(): Observable<boolean> {
    return this.subject.asObservable();
  }

  ngOnDestroy() {
    document.removeEventListener('hidden', this.onVisibilityChange);
    this.subject.complete();
  }
}
