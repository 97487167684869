import { LegacyAny } from '@soracom/shared/core';

import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SearchQuery,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { SoraCamEvent } from '../core/sora_cam_event';

export class SoraCamEventsService implements PaginatableService<SoraCamEvent> {
  static $inject: InjectList = ['BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'sora_cam/devices/events';

  constructor(
    private soracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(paginationOptions: PaginationOptions, searchQuery?: SearchQuery): Promise<ScRelation<SoraCamEvent>> {
    const apiParams: SoracomApiParams = {
      path: `/v1/${this.resourcePath}`,
      query: { ...paginationOptions },
      searchQuery,
    };
    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      const data = res.data;
      const links = this.paginationService.getPaginationLinks(res.headers.link);
      return { data, links };
    });
  }
}
