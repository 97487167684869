import { Injectable, inject } from '@angular/core';
import { ListApiOptions, PaginationOptions } from '@soracom/shared/soracom-services-ui/groups-ui';
import { LegacyBaseSoracomApiService, PaginatableService } from '@user-console/legacy-soracom-api-client';
import { InjectList } from 'apps/user-console/app/shared/core/injectable';
import { SearchQuery } from '@soracom/shared/api-helper';

export const ShareStatus = {
  Pending: 0,
  Accepted: 1,
} as const;

type AtomUserId = string;
type BaseSoraCamDeviceShare = {
  shareId: string;
  deviceId: string;
  deviceName: string;
  deviceProductDisplayName: string;
  isAccepted: boolean;
  sharedTime: number;
};
type SoraCamDeviceSentShare = BaseSoraCamDeviceShare & { recipientAtomUserId: AtomUserId };
type SoraCamDeviceReceivedShare = BaseSoraCamDeviceShare & { ownerAtomUserId: AtomUserId };
type SoraCamDeviceShare = SoraCamDeviceSentShare | SoraCamDeviceReceivedShare;
type SoraCamDeviceShareListResponse<T extends SoraCamDeviceShare> = {
  data: T[];
};

type SoraCamDeviceShareItem<T extends SoraCamDeviceShare> = Omit<T, 'isAccpeted' | 'sharedTime'> & {
  status: (typeof ShareStatus)[keyof typeof ShareStatus];
  sentAt: number;
};

export type SoraCamDeviceSentShareItem = SoraCamDeviceShareItem<SoraCamDeviceSentShare>;
export type SoraCamDeviceReceivedShareItem = SoraCamDeviceShareItem<SoraCamDeviceReceivedShare>;

abstract class SoraCamDeviceShareService<T extends SoraCamDeviceShare>
  implements PaginatableService<SoraCamDeviceShareItem<T>>
{
  static get $inject(): InjectList {
    return ['BaseSoracomApiService'];
  }
  abstract resourcePath: string;
  protected soracomApi = inject(LegacyBaseSoracomApiService);

  list(paginationOptions: PaginationOptions, searchQuery?: SearchQuery, apiOptions?: ListApiOptions) {
    const path = `/v1/${this.resourcePath}`;
    const limit = paginationOptions.limit ?? 20;
    const options = paginationOptions.last_evaluated_key
      ? {
          query: {
            to: paginationOptions.last_evaluated_key,
          },
        }
      : {};
    const apiPromise: Promise<SoraCamDeviceShareListResponse<T>> = this.soracomApi.callApiWithToken({
      method: 'GET',
      path,
      ...options,
    });
    return apiPromise.then((res) => {
      const data: SoraCamDeviceShareItem<T>[] = res.data.map((d) => ({
        ...d,
        status: d.isAccepted ? ShareStatus.Accepted : ShareStatus.Pending,
        sentAt: d.sharedTime,
      }));
      const links =
        data.length === limit
          ? { next: { lastEvaluatedKey: `${data[limit - 1].sentAt}`, url: `${path}?to=${data[limit - 1].sentAt}` } }
          : {};
      return { data, links };
    });
  }
}

@Injectable()
export class SoraCamDeviceSentShareService extends SoraCamDeviceShareService<SoraCamDeviceSentShare> {
  resourcePath = 'sora_cam/devices/atom_cam/shares';

  async share(deviceIds: string[], atomUserId: string): Promise<void> {
    const res = await this.soracomApi.callApiWithToken({
      method: 'POST',
      path: `/v1/${this.resourcePath}`,
      contentType: 'application/json',
      body: { atomUserId, deviceIds },
    });
    return res.data;
  }

  async cancel(shareId: string): Promise<void> {
    await this.soracomApi.callApiWithToken({
      method: 'DELETE',
      path: `/v1/${this.resourcePath}/${shareId}`,
      contentType: 'application/json',
    });
  }
}

@Injectable()
export class SoraCamDeviceReceivedShareService extends SoraCamDeviceShareService<SoraCamDeviceReceivedShare> {
  resourcePath = 'sora_cam/devices/atom_cam/shared';

  async accept(shareIds: string[]): Promise<void> {
    await this.soracomApi.callApiWithToken({
      method: 'POST',
      path: `/v1/${this.resourcePath}/accept`,
      contentType: 'application/json',
      body: {
        shareIds,
        accept: true,
      },
    });
  }

  async decline(shareIds: string[]): Promise<void> {
    await this.soracomApi.callApiWithToken({
      method: 'POST',
      path: `/v1/${this.resourcePath}/accept`,
      contentType: 'application/json',
      body: {
        shareIds,
        accept: false,
      },
    });
  }

  async cancel(deviceId: string): Promise<void> {
    await this.soracomApi.callApiWithToken({
      method: 'DELETE',
      path: `/v1/sora_cam/devices/${deviceId}`,
      contentType: 'application/json',
    });
  }
}
