// ref: https://github.com/soracom/sora-cam-devices-api/blob/main/lambda/common/export_status.go
// ExportStatus_Initializing ExportStatus = "initializing"
// ExportStatus_Processing   ExportStatus = "processing"
// ExportStatus_Retrying     ExportStatus = "retrying"
// ExportStatus_Completed    ExportStatus = "completed"
// ExportStatus_Failed       ExportStatus = "failed"
// ExportStatus_Expired      ExportStatus = "expired"
// ExportStatus_Unknown      ExportStatus = "unknown"
export type ExportStatus =
  | 'initializing'
  | 'processing'
  | 'retrying'
  | 'completed'
  | 'failed'
  | 'expired'
  | 'unknown'
  | 'limitExceeded';

// Request information about export video
export interface SoraCamExportApiModel {
  readonly operatorId: string;
  readonly deviceId: string;
  readonly exportId: string;
  readonly requestedTime: number;
  readonly status: ExportStatus;
  readonly url?: string;
  readonly expiryTime?: number;
}

export type SoraCamExport = SoraCamExportApiModel & {
  readonly id: string;
};

export const toSoraCamExport = (v: SoraCamExportApiModel): SoraCamExport => ({
  ...v,
  id: v.exportId,
});
