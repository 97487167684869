import { LegacyAny } from '@soracom/shared/core';

import { PaginationOptions, ScRelation } from '../components/paginator';
import {
  HttpLinkHeader,
  LegacyBaseSoracomApiService,
  PaginatableService,
  PaginationService,
  SoracomApiParams,
} from '@user-console/legacy-soracom-api-client';
import { InjectList } from '../core/injectable';
import { ExportStatus, SoraCamExport, toSoraCamExport } from '../core/sora_cam_export';

export interface CreateExportParams {
  from?: number;
  to?: number;
}

export interface CreateExportResponse {
  operatorId: string;
  deviceId: string;
  exportId: string;
  status: ExportStatus;
  url?: string;
}

export interface CreateExportParams {
  from?: number;
  to?: number;
}

export interface CreateExportResponse {
  operatorId: string;
  deviceId: string;
  exportId: string;
  status: ExportStatus;
  url?: string;
}

export class SoraCamVideoExportsService implements PaginatableService<SoraCamExport> {
  static $inject: InjectList = ['BaseSoracomApiService', 'PaginationService'];

  resourcePath = 'sora_cam/devices';

  constructor(
    private soracomApi: LegacyBaseSoracomApiService,
    private paginationService: PaginationService,
  ) {}

  list(
    paginationOptions: PaginationOptions | { deviceId: string } /* , searchQuery: SearchQuery */,
  ): Promise<ScRelation<SoraCamExport>> {
    const apiParams: SoracomApiParams = {
      method: 'GET',
      path: `/v1/${this.resourcePath}/videos/exports`,
      contentType: 'application/json',
      query: { ...paginationOptions },
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      const data = res.data.map((d: LegacyAny) => toSoraCamExport(d));
      // const links = this.paginationService.getPaginationLinks(res.headers.link);
      const lastEvaluatedKey = res.headers['x-soracom-next-key'];
      // @ts-expect-error (legacy code incremental fix)
      const links: HttpLinkHeader = lastEvaluatedKey ? { next: { lastEvaluatedKey, url: null } } : {};
      return { data, links };
    });
  }

  listForDevice(deviceId: string, paginationOptions?: PaginationOptions): Promise<ScRelation<SoraCamExport>> {
    const apiParams: SoracomApiParams = {
      method: 'GET',
      path: `/v1/${this.resourcePath}/${deviceId}/videos/exports`,
      contentType: 'application/json',
      query: { ...paginationOptions },
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      const data = res.data.map((d: LegacyAny) => toSoraCamExport(d));
      // const links = this.paginationService.getPaginationLinks(res.headers.link);
      const lastEvaluatedKey = res.headers['x-soracom-next-key'];
      // @ts-expect-error (legacy code incremental fix)
      const links: HttpLinkHeader = lastEvaluatedKey ? { next: { lastEvaluatedKey, url: null } } : {};
      return { data, links };
    });
  }

  get(deviceId: string, exportId: string): Promise<SoraCamExport> {
    const apiParams: SoracomApiParams = {
      method: 'GET',
      path: `/v1/${this.resourcePath}/${deviceId}/videos/exports/${exportId}`,
      contentType: 'application/json',
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return toSoraCamExport(res.data);
    });
  }

  create(id: string, params: CreateExportParams): Promise<SoraCamExport> {
    const apiParams: SoracomApiParams = {
      method: 'POST',
      path: `/v1/${this.resourcePath}/${id}/videos/exports`,
      contentType: 'application/json',
      body: {
        ...params,
      },
    };

    return this.soracomApi.callApiWithToken(apiParams).then((res: LegacyAny) => {
      return toSoraCamExport(res.data);
    });
  }
}
